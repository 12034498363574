import moment from "moment-timezone";
import { apiConstants } from "../apis/constants";
const defaultTimeZone = 'Asia/Ho_Chi_Minh';
const dateTimeFormat = 'DD/MM/YYYY HH:mm';
const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';

const convertToDateTime = (ISOString, format, timeZone) => {
   let stillUtc = moment.utc(ISOString).toDate();
   return moment(stillUtc).tz(timeZone || defaultTimeZone).format(format || dateTimeFormat);
}
const convertToDate = (ISOString, format, timeZone) => {
   let stillUtc = moment.utc(ISOString).toDate();
   return moment(stillUtc).tz(timeZone || defaultTimeZone).format(format || dateFormat);
}
const convertToTime = (ISOString, format, timeZone) => {
   let stillUtc = moment.utc(ISOString).toDate();
   return moment(stillUtc).tz(timeZone || defaultTimeZone).format(format || timeFormat);
}


const getYoutubeIdFromUrl = (url) => {
   const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
   let match = url.match(regExp);
   if (match && match[1]) {
      return match[1];
   }
   return '';
}
const createEmbedYoutubeUrl = (url) => {
   let ytId = getYoutubeIdFromUrl(url);
   if (ytId) {
      return `//www.youtube.com/embed/${ytId}`;
   }
   return '';
}

const getYoutubeThumbnailImg = (url) => {
   let ytId = getYoutubeIdFromUrl(url);
   if (ytId) {
      return `//img.youtube.com/vi/${ytId}/hqdefault.jpg`;
   }
   return '';
}


const getProjectThumbnail = (item) => {
   if (item.images && item.images.length !== 0) {
      let allImages = item.images;
      if (allImages.length && item.ordinal_images && item.ordinal_images.length) {
         allImages = getSortedImageList(item.images, item.ordinal_images);
      } else {
         allImages = item.images ? _.orderBy(item.images, ['updated_at'], ['desc']) : [];
      }

      let lastedImg = allImages[0];
      // console.log(lastedImg);
      if (lastedImg && lastedImg.formats && lastedImg.formats.small) {
         let imgUrl = (lastedImg.formats.small.url) ? lastedImg.formats.small.url : '';
         if (imgUrl) {
            return `${apiConstants.API_CMS}${imgUrl}`;
         }
      } else if (lastedImg && lastedImg.url) {
         return `${apiConstants.API_CMS}${lastedImg.url}`;
      }
   }
   return '';
}

const getImageThumbnail = (item) => {
   if (item.formats && item.formats.small) {
      return `${apiConstants.API_CMS}${item.formats.small.url}`;
   } else if (item.url) {
      return `${apiConstants.API_CMS}${item.url}`;
   }
   return '';
}

const isValidURL = (string) => {
   let pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
   return pattern.test(string);
};

const getSortedImageList = (currentList, sortedList) => {
   if (currentList && currentList.length && sortedList && sortedList.length) {
      let results = [];
      sortedList.forEach(sortedId => {
         let exactItem = currentList.find(i => i.id === sortedId);
         if (exactItem) {
            results.push(exactItem);
            currentList = currentList.filter(i => i.id !== sortedId);
         }
      });
      if (currentList.length) {
         let retainedArr = currentList.map(i => i.id);
         results = [...results, ...retainedArr];
      }
      return results;
   }
   return [];
}

export const common = {
   convertToDateTime,
   convertToDate,
   convertToTime,
   getYoutubeIdFromUrl,
   createEmbedYoutubeUrl,
   getYoutubeThumbnailImg,
   getProjectThumbnail,
   getImageThumbnail,
   isValidURL,
   getSortedImageList,
}