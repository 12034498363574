import React, { PureComponent } from 'react';

class IconClose extends PureComponent {
   render() {
      return (
         <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M3 3L21 21"
               stroke="white"
               strokeWidth="1.5"
               strokeMiterlimit={10}
               strokeLinejoin="round"
            />
            <path
               d="M3 21L21 2.99997"
               stroke="white"
               strokeWidth="1.5"
               strokeMiterlimit={10}
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconClose;