import React, { PureComponent } from 'react';
import { localeFunction } from '../locales/functions';
import Cookies from 'js-cookie';
import Router from 'next/router';

class ChangeLanguage extends PureComponent {

   changeLanguage = async (lang) => {
      if (lang === localeFunction.VIETNAMESE || lang === localeFunction.ENGLISH) {
         let currentLang = Cookies.get(localeFunction.LANGUAGE);
         if (currentLang !== lang) {
            await Cookies.set(localeFunction.LANGUAGE, lang);
            setTimeout(() => {
               // window.location.reload();
               Router.reload();
            }, 100);
         }
      }
   }

   render() {
      const currentLang = Cookies.get(localeFunction.LANGUAGE);
      return (
         <div className="change-language">
            <span className={currentLang !== localeFunction.ENGLISH ? 'active' : ''} onClick={() => this.changeLanguage(localeFunction.VIETNAMESE)}>VN</span>|
            <span className={currentLang === localeFunction.ENGLISH ? 'active' : ''} onClick={() => this.changeLanguage(localeFunction.ENGLISH)}>EN</span>
         </div>
      );
   }
}

export default ChangeLanguage;