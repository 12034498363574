import React, { PureComponent } from 'react';

class DefaultBg extends PureComponent {
   render() {
      return (
         <div className="default-bg-svg"
            style={{
               background: '#F3F3F3',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               width: '100%',
               height: '100%',
            }}
         >
            <svg
               width={67}
               height={54}
               viewBox="0 0 67 54"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M21.6472 15.1831C25.731 15.1831 29.0416 11.8726 29.0416 7.78878C29.0416 3.70498 25.731 0.394409 21.6472 0.394409C17.5634 0.394409 14.2528 3.70498 14.2528 7.78878C14.2528 11.8726 17.5634 15.1831 21.6472 15.1831Z"
                  fill="#DCDCDC"
               />
               <path
                  d="M65.8803 51.6426L44.7535 13.6144C44.5782 13.2986 44.2549 13.0947 43.8947 13.0725C43.5218 13.0556 43.188 13.2141 42.9757 13.5056L28.8747 32.8958L36.0377 43.0356L17.0257 28.0905C16.7912 27.9035 16.4891 27.8254 16.1923 27.874C15.8965 27.9236 15.6356 28.0958 15.474 28.3482L0.685227 51.5877C0.478185 51.9141 0.465509 52.325 0.650368 52.6641C0.836284 53.0011 1.19121 53.2113 1.57678 53.2113H64.9571C65.331 53.2113 65.6775 53.0127 65.8676 52.6905C66.0578 52.3683 66.062 51.969 65.8803 51.6426Z"
                  fill="#DCDCDC"
               />
            </svg>
         </div>

      );
   }
}

export default DefaultBg;