import React, { PureComponent } from 'react';

class IconPrev extends PureComponent {
   render() {
      return (
         <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M9.94862 16.4993L2.44862 8.99927L9.94862 1.49927M2.26611 8.96749H15.7339"
               stroke="currentColor"
               strokeWidth="1.5"
            />
         </svg>
      );
   }
}

export default IconPrev;